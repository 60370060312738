<script>
    import {onMount} from "svelte";
    import {fly} from "svelte/transition";
    import Tailwind from "./Tailwind.svelte";
    import PDFPage from "./PDFPage.svelte";
    import Image from "./Image.svelte";
    import Text from "./Text.svelte";
    import Drawing from "./Drawing.svelte";
    import DrawingCanvas from "./DrawingCanvas.svelte";
    import prepareAssets, {fetchFont, getAsset} from "./utils/prepareAssets.js";
    import {
        readAsArrayBuffer,
        readAsImage,
        readAsPDF,
        readAsDataURL,
        readQueryParam,
        decodeData
    } from "./utils/asyncReader.js";
    import {ggID, getUrlFilename} from "./utils/helper.js";
    import {save, saveFromUrl} from "./utils/PDF.js";

    const genID = ggID();
    let pdfFile;
    let pdfName = "";
    let pages = [];
    let pagesScale = [];
    let allObjects = [];
    let currentFont = "Times-Roman";
    let focusId = null;
    let selectedPageIndex = -1;
    let saving = false;
    let addingDrawing = false;
    let showSelectButton = false;
    let message = "";
    let messageId = null;
    let loading = false;
    const passedUrl = readQueryParam("url");
    const initialPoint = {
        x: 100,
        y: window.innerHeight / 2.5,
    }

    function notify(notification, timeout = 7000) {
        if (messageId) clearTimeout(messageId);

        message = notification;
        messageId = setTimeout(() => message = "", timeout);
    }

    onMount(async () => {
        try {
            showSelectButton = !passedUrl;

            if (passedUrl) {
                await addPdfFromUrl(passedUrl);
                selectedPageIndex = 0;
                setTimeout(() => {
                    fetchFont(currentFont);
                    prepareAssets();
                }, 5000);
            }
        } catch (e) {
            console.log(e);
        }
    });

    async function onUploadPDF(e) {
        const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
        const file = files[0];
        if (!file || file.type !== "application/pdf") return;
        selectedPageIndex = -1;
        try {
            await addPDF(file);
            selectedPageIndex = 0;
        } catch (e) {
            console.log(e);
            notify("Failed to load pdf");
        }
    }


    async function addPdfFromUrl(url) {
        try {
            loading = true;
            const pdfjsLib = await getAsset('pdfjsLib');
            const pdf = await pdfjsLib.getDocument(url).promise;
            await preparePDF(pdf, {
                fileName:  readQueryParam('name') || getUrlFilename(url)
            });
            loading = false;
        } catch (e) {
            notify("Failed to load pdf");
            throw e;
        }
    }

    async function addPDF(file) {
        try {
            const pdf = await readAsPDF(file);

            await preparePDF(pdf, {file});

        } catch (e) {
            notify("Failed to load pdf");
        }
    }

    async function preparePDF(pdf, {file, fileName} = {}) {
        try {
            pdfName = fileName || file.name || "Untitled";
            pdfFile = file;
            const numPages = pdf.numPages;
            pages = Array(numPages)
                .fill()
                .map((_, i) => pdf.getPage(i + 1));
            allObjects = pages.map(() => []);
            pagesScale = Array(numPages).fill(1);
        } catch (e) {
            showSelectButton = true;
            notify("Failed to load pdf");
            throw e;
        }
    }

    async function onUploadImage(e) {
        const file = e.target.files[0];
        if (file && selectedPageIndex >= 0) {
            addImage(file);
        }
        e.target.value = null;
    }

    async function addImage(file) {
        try {
            // get dataURL to prevent canvas from tainted
            const url = await readAsDataURL(file);
            const img = await readAsImage(url);
            const id = genID();
            const {width, height} = img;
            const object = {
                id,
                type: "image",
                width,
                height,
                payload: img,
                file,
                ...initialPoint
            };
            allObjects = allObjects.map((objects, pIndex) =>
                pIndex === selectedPageIndex ? [...objects, object] : objects
            );
        } catch (e) {
            console.log(`Fail to add image.`, e);
        }
    }

    function onAddTextField() {
        if (selectedPageIndex >= 0) {
            addTextField();
        }
    }

    function addTextField(text = "New Text Field") {
        const id = genID();
        fetchFont(currentFont);
        const object = {
            id,
            text,
            type: "text",
            size: 16,
            width: 0, // recalculate after editing
            lineHeight: 1.4,
            fontFamily: currentFont,
            ...initialPoint
        };
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex === selectedPageIndex ? [...objects, object] : objects
        );
    }

    function onAddDrawing() {
        if (selectedPageIndex >= 0) {
            addingDrawing = true;
        }
    }

    function addDrawing(originWidth, originHeight, path, scale = 1) {
        const id = genID();
        const object = {
            id,
            path,
            type: "drawing",
            originWidth,
            originHeight,
            width: originWidth * scale,
            scale,
            ...initialPoint
        };
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex === selectedPageIndex ? [...objects, object] : objects
        );
    }

    function selectFontFamily(event) {
        const name = event.detail.name;
        fetchFont(name);
        currentFont = name;
    }

    function selectPage(index) {
        selectedPageIndex = index;
    }

    function updateObject(objectId, payload) {
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex == selectedPageIndex
                ? objects.map(object =>
                    object.id === objectId ? {...object, ...payload} : object
                )
                : objects
        );
    }

    function deleteObject(objectId) {
        allObjects = allObjects.map((objects, pIndex) =>
            pIndex == selectedPageIndex
                ? objects.filter(object => object.id !== objectId)
                : objects
        );
    }

    function onMeasure(scale, i) {
        pagesScale[i] = scale;
    }

    // FIXME: Should wait all objects finish their async work
    async function savePDF() {
        if ((!passedUrl && !pdfFile) || saving || !pages.length) return;

        saving = true;
        try {
            if(passedUrl){
                await saveFromUrl(passedUrl, allObjects, pdfName, pagesScale)
            } else {
                await save(pdfFile, allObjects, pdfName, pagesScale);
            }
        } catch (e) {
            console.log(e);
        } finally {
            saving = false;
        }
    }
</script>

<svelte:window
        on:dragenter|preventDefault
        on:dragover|preventDefault
        on:drop|preventDefault={onUploadPDF}/>
<Tailwind/>
<main class="flex flex-col items-center py-16 bg-gray-200 min-h-screen">
    <div class="fixed z-10 top-0 left-0 right-0 h-12 flex justify-center shadow items-center bg-white border-b border-gray-300">
        <div class="container flex items-center">
            <span class="font-medium mr-auto">DokFlow Sign</span>
            <div class="justify-center mr-3 md:mr-4 w-full max-w-xs hidden md:flex">
                <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name"/>
                <input id="text"
                       placeholder="PDF File Name..."
                       type="text"
                       class="flex-grow border border-gray-300 bg-gray-200 rounded-full py-1 px-16 md:px-3"
                       bind:value={pdfName}/>
            </div>
            <button on:click={savePDF}
                    class="px-8 py-1 rounded-full text-white font-bold text-sm md:text-base bg-indigo-900 hover:bg-blue-700"
                    class:cursor-not-allowed={pages.length === 0 || saving || (!pdfFile && !passedUrl)}
                    class:bg-blue-700={pages.length === 0 || saving || !pdfFile}>
                {saving ? 'Saving' : 'Save'}
            </button>
        </div>
    </div>
    {#if addingDrawing}
        <div
                transition:fly={{ y: -200, duration: 500 }}
                class="fixed z-10 top-0 left-0 right-0 border-b border-gray-300 bg-white
      shadow-lg"
                style="height: 50%;">
            <DrawingCanvas
                    on:finish={e => {
          const { originWidth, originHeight, path } = e.detail;
          let scale = 1;
          if (originWidth > 500) {
            scale = 500 / originWidth;
          }
          addDrawing(originWidth, originHeight, path, scale);
          addingDrawing = false;
        }}
                    on:cancel={() => (addingDrawing = false)}/>
        </div>
    {/if}
    {#if pages.length}
        <div class="flex justify-center px-5 w-full md:hidden">
            <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name"/>
            <input placeholder="PDF File Name..."
                   type="text"
                   class="flex-grow bg-transparent px-8"
                   bind:value={pdfName}/>
        </div>
        <div class="w-full">
            {#each pages as page, pIndex (page)}
                <div
                        class="p-5 w-full flex flex-col items-center overflow-hidden"
                        on:mousedown={() => selectPage(pIndex)}
                        on:touchstart={() => selectPage(pIndex)}>
                    <div
                            class="relative shadow-lg"
                            class:shadow-outline={pIndex === selectedPageIndex}>
                        <PDFPage
                                on:measure={e => onMeasure(e.detail.scale, pIndex)}
                                {page}/>
                        <div
                                class="absolute top-0 left-0 transform origin-top-left"
                                style="transform: scale({pagesScale[pIndex]}); touch-action: none;">
                            {#each allObjects[pIndex] as object (object.id)}
                                {#if object.type === 'image'}
                                    <Image
                                            on:update={e => updateObject(object.id, e.detail)}
                                            on:delete={() => deleteObject(object.id)}
                                            file={object.file}
                                            payload={object.payload}
                                            x={object.x}
                                            y={object.y}
                                            width={object.width}
                                            height={object.height}
                                            pageScale={pagesScale[pIndex]}/>
                                {:else if object.type === 'text'}
                                    <Text
                                            on:update={e => updateObject(object.id, e.detail)}
                                            on:delete={() => deleteObject(object.id)}
                                            on:selectFont={selectFontFamily}
                                            text={object.text}
                                            x={object.x}
                                            y={object.y}
                                            size={object.size}
                                            lineHeight={object.lineHeight}
                                            fontFamily={object.fontFamily}
                                            pageScale={pagesScale[pIndex]}/>
                                {:else if object.type === 'drawing'}
                                    <Drawing
                                            on:update={e => updateObject(object.id, e.detail)}
                                            on:delete={() => deleteObject(object.id)}
                                            path={object.path}
                                            x={object.x}
                                            y={object.y}
                                            width={object.width}
                                            originWidth={object.originWidth}
                                            originHeight={object.originHeight}
                                            pageScale={pagesScale[pIndex]}/>
                                {/if}
                            {/each}

                        </div>
                    </div>
                </div>
            {/each}
        </div>
    {:else}
        <div class="w-full flex-grow flex justify-center items-center">
            <span class=" font-bold text-3xl text-gray-500">{ loading ? 'Downloading PDF...' : 'Drag something here' }</span>
        </div>
    {/if}

    <div class="fixed z-10 bottom-0 left-0 right-0 h-12 flex justify-center shadow items-center bg-gray-400 text-center py-4 lg:px-4">

        <input type="file"
               id="image"
               name="image"
               class="hidden"
               accept="image/*"
               on:change={onUploadImage}/>

        <div class="inline-flex">
            {#if showSelectButton}
                <input type="file"
                       name="pdf"
                       id="pdf"
                       accept="application/pdf"
                       on:change={onUploadPDF}
                       class="hidden"/>
                <label for="pdf"
                       class="cursor-pointer bg-gray-300 hover:bg-indigo-400 text-gray-800 font-bold py-2 px-8 rounded-full m-1">
                    <svg class="h-5 inline-block mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M6.5 20Q4.22 20 2.61 18.43 1 16.85 1 14.58 1 12.63 2.17 11.1 3.35 9.57 5.25 9.15 5.88 6.85 7.75 5.43 9.63 4 12 4 14.93 4 16.96 6.04 19 8.07 19 11 20.73 11.2 21.86 12.5 23 13.78 23 15.5 23 17.38 21.69 18.69 20.38 20 18.5 20H13Q12.18 20 11.59 19.41 11 18.83 11 18V12.85L9.4 14.4L8 13L12 9L16 13L14.6 14.4L13 12.85V18H18.5Q19.55 18 20.27 17.27 21 16.55 21 15.5 21 14.45 20.27 13.73 19.55 13 18.5 13H17V11Q17 8.93 15.54 7.46 14.08 6 12 6 9.93 6 8.46 7.46 7 8.93 7 11H6.5Q5.05 11 4.03 12.03 3 13.05 3 14.5 3 15.95 4.03 17 5.05 18 6.5 18H9V20M12 13Z"/>
                    </svg>
                    Choose PDF
                </label>
            {/if}
            <label for="image"
                   class:cursor-not-allowed={selectedPageIndex < 0}
                   class:opacity-50={selectedPageIndex < 0}
                   class="cursor-pointer bg-gray-300 hover:bg-indigo-400 text-gray-800 font-bold py-2 px-8 rounded-full m-1">
                <svg class="h-5 inline-block mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M22.7 14.3L21.7 15.3L19.7 13.3L20.7 12.3C20.8 12.2 20.9 12.1 21.1 12.1C21.2 12.1 21.4 12.2 21.5 12.3L22.8 13.6C22.9 13.8 22.9 14.1 22.7 14.3M13 19.9V22H15.1L21.2 15.9L19.2 13.9L13 19.9M21 5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11V19.1L12.1 18H5L8.5 13.5L11 16.5L14.5 12L16.1 14.1L21 9.1V5Z"/>
                </svg>
                <span>Image Signature</span>
            </label>
            <button class:cursor-not-allowed={selectedPageIndex < 0}
                    class:opacity-50={selectedPageIndex < 0}
                    on:click={onAddTextField}
                    class="cursor-pointer bg-gray-300 hover:bg-indigo-400 text-gray-800 font-bold py-2 px-8 rounded-full m-1">
                <svg class="h-5 inline-block mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17,8H20V20H21V21H17V20H18V17H14L12.5,20H14V21H10V20H11L17,8M18,9L14.5,16H18V9M5,3H10C11.11,3 12,3.89 12,5V16H9V11H6V16H3V5C3,3.89 3.89,3 5,3M6,5V9H9V5H6Z"/>
                </svg>
                <span>Text Field</span>
            </button>
            <button on:click={onAddDrawing}
                    class:cursor-not-allowed={selectedPageIndex < 0}
                    class:opacity-50={selectedPageIndex < 0}
                    class="cursor-pointer bg-gray-300 hover:bg-indigo-400 text-gray-800 font-bold py-2 px-8 rounded-full m-1">
                <svg class="h-5 inline-block mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M9.75 20.85C11.53 20.15 11.14 18.22 10.24 17C9.35 15.75 8.12 14.89 6.88 14.06C6 13.5 5.19 12.8 4.54 12C4.26 11.67 3.69 11.06 4.27 10.94C4.86 10.82 5.88 11.4 6.4 11.62C7.31 12 8.21 12.44 9.05 12.96L10.06 11.26C8.5 10.23 6.5 9.32 4.64 9.05C3.58 8.89 2.46 9.11 2.1 10.26C1.78 11.25 2.29 12.25 2.87 13.03C4.24 14.86 6.37 15.74 7.96 17.32C8.3 17.65 8.71 18.04 8.91 18.5C9.12 18.94 9.07 18.97 8.6 18.97C7.36 18.97 5.81 18 4.8 17.36L3.79 19.06C5.32 20 7.88 21.47 9.75 20.85M18.96 7.33L13.29 13H11V10.71L16.67 5.03L18.96 7.33M22.36 6.55C22.35 6.85 22.04 7.16 21.72 7.47L19.2 10L18.33 9.13L20.93 6.54L20.34 5.95L19.67 6.62L17.38 4.33L19.53 2.18C19.77 1.94 20.16 1.94 20.39 2.18L21.82 3.61C22.06 3.83 22.06 4.23 21.82 4.47C21.61 4.68 21.41 4.88 21.41 5.08C21.39 5.28 21.59 5.5 21.79 5.67C22.08 5.97 22.37 6.25 22.36 6.55Z"/>
                </svg>
                <span>Signature</span>
            </button>
        </div>
    </div>

    {#if message !== ''}
        <div class="fixed bottom-0 mb-16 left-0 right-0 z-10 flex items-center justify-center">
            <div class="py-2 px-8 bg-red-200 items-center shadow-2xl text-red-800 leading-none lg:rounded-full flex lg:inline-flex"
                 role="alert">
                <span class="flex rounded-full uppercase p-1 text-xs font-bold mr-3">🔔</span>
                <span class="font-semibold mr-2 text-left flex-auto">{ message || 'Message' }</span>
            </div>
        </div>
    {/if}
</main>
