export function ggID() {
  let id = 0;
  return function genId() {
    return id++;
  };
}
export function timeout(ms) {
  return new Promise((res) => setTimeout(res, ms));
}
export const noop = () => {};


export function getUrlFilename(url) {
    // remove query string
    url = url.split('?')[0];
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
}